import './dashBoardWelcome.css'

function DashBoardWelcome (){
        return(
            <div className='div-welcome'>
                <h1 className='welcome-h1'>welcome to</h1>
                <h1 className='welcome-h2'>DashBoard</h1>
            </div>
        );
}

export default DashBoardWelcome;